.Builder {
}

.gjs-pn-views-container, .gjs-blocks-c,.gjs-pn-options,.gjs-editor,.gjs-pn-panel,.gjs-pn-buttons
{
    background: #2B404E;
}

.gjs-block{
    background-color: rgba(97, 218, 251, 0.2);
}
.gjs-title, .gjs-layer-title, .gjs-block-category,.gjs-layer-title-c {
    background-color: rgba(97, 218, 251, 0.2);

}
.gjs-selected {
    background: rgba(97, 218, 251);;
}
.gjs-block:hover, .gjs-pn-active{
    color: rgba(97, 218, 251);
}
.gjs-pn-main-menu-container {
    /* border-radius: 0 0 8px 0 */
}

.gjs-pn-main-menu-container .main-menu {
    background: rgba(97, 218, 251, 0.2);
}
.gjs-pn-main-menu-container .project-name,
.gjs-pn-main-menu-container .project-type {
    cursor: default;
    color: #b5b5b5;
}

.gjs-pn-main-menu-container .project-type {
    letter-spacing: 1px;
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 0;
    padding-left: 0;
    margin-left: 10px;
}

.gjs-pn-main-menu-container .project-name{
    font-size: 11px;

}

.gjs-pn-main-menu-container:hover #main-nav.hide {
    display: block;
}
#main-nav.hide {display: none;}
#main-nav ul {
    text-align: left;
    list-style: none;
    padding: 0;
}
#main-nav ul li {
    padding: 10px 10px;
    border-bottom: 1px solid #333
}
#main-nav ul li:hover {
    background: #333;
    cursor: pointer;
}

.gjs-cv-canvas,
.gjs-cv-canvas__frames {
    width: 1380px;
    left: 0;
    right: 0;
    margin: auto
}